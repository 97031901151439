<template>
  <div>
    <Home/>
    <About/>
    <Specifications/>
    <Partners/>
  </div>
</template>

<script>
import Home from './Home'
import About from './About'
import Partners from './Partners.vue'
import Specifications from './Specifications'
export default {
  components: { About,  Specifications, Partners, Home},
  data: () => ({
    
  }),
}
</script>



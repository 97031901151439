<template>
  <v-container >
      <v-row>
        <!-- Image Container -->
        <v-col
          v-if="!$vuetify.breakpoint.mobile"
          cols="12"
          md="6"
          order="1"
          order-md="1"
        >

        <h1 class="app-subtitle">Serviços</h1><br/>
          <v-img
            max-height="600"
            max-width="500"
            src="../assets/specifications.png"
            class="float-image-left"
          >
          </v-img>
        </v-col>
          <br>
          <br>
        <!-- Text Container  -->
        <v-col 
          cols="12"
          md="6"
          order="2"
          order-md="2"
        >
        <div class="container-text">
          <v-row>
             <v-icon large color="blue darken-4" style="margin-right: 5%;">mdi-console-line</v-icon>
            <h4>IT Service Management – ITSM</h4>
            <span>O ServiceNow oferece computação em nuvem como serviço, incluindo software de gerenciamento de serviços empresariais de 
              plataforma como serviço (PaaS) para recursos humanos, jurídico, gerenciamento de instalações, financeiro, marketing e operações de campo.
            </span>
          </v-row>
          <br/> <br/>
          <v-row>
             <v-icon large color="blue darken-4" style="margin-right: 5%;">mdi-database-outline</v-icon>
            <h4>IT Service Management – ITSM</h4>
            <span>O ServiceNow oferece computação em nuvem como serviço, incluindo software de gerenciamento de serviços empresariais de 
              plataforma como serviço (PaaS) para recursos humanos, jurídico, gerenciamento de instalações, financeiro, marketing e operações de campo.
            </span>
          </v-row>
          <br/> <br/>
          <v-row>
             <v-icon large color="blue darken-4" style="margin-right: 5%;">mdi-desktop-mac</v-icon>
            <h4>IT Service Management – ITSM</h4>
            <span>O ServiceNow oferece computação em nuvem como serviço, incluindo software de gerenciamento de serviços empresariais de 
              plataforma como serviço (PaaS) para recursos humanos, jurídico, gerenciamento de instalações, financeiro, marketing e operações de campo.
            </span>
            <br>
            <br>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    
  }),
}
</script>
<style lang="scss" scoped>
h4{
  font-family: 'Montserrat';
  font-size: 20px;
  line-height: 160%;

  color: #414148;
}

.app-subtitle{
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 700 !important;
  line-height: 120% !important;

  color: #414148 !important;
}

span {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: justify;

  /* or 24px */
  color: #414148;
}

.container-text {
  margin-left: 5%;
}

.float-image-left {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  border: solid #777777;
}

@media only screen and (max-device-width: 700px) {
  span {
    text-align: left !important;
  }
}
</style>>


<template>
  <div class="background">   
    <v-container style="height: 100vh;">
      <v-row>
        <!-- Title Container -->
        <v-col
          cols="12"
          subtitle-1
          md="6"
          order="2"
          order-md="1"     
        >
          <transition
            appear
            name="slide-fade"   
            transition="slide-x-transition"
          >
            <div class="app-title">
              <h1 class="text-caption title">Sarko sua melhor escolha sempre.</h1><br/>
              <span class="span">Garantia de segurança em qualquer situação.</span>
            </div>
          </transition>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    
  }),
}
</script>
<style lang="scss" scoped>

.background {
  width: 100vw;
  height: 100vh;
  background-position: 30% 45%;
  background-size: cover;
  background-image: url('../assets/Group677.png') ;
}
.app-title {
  margin-top: 30%;
}

.title{
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 700 !important;
  font-size: 45px !important;
  line-height: 110% !important;

  color: #212121 !important;
}

.span {
  font-family: 'Montserrat';
  font-size: 22px;
  line-height: 120%;
  /* or 24px */

  letter-spacing: -0.01em;
  float: left;

  color: #414148;
}

.app-button {
  /* Inside Auto Layout */
  margin-top: 15%;
  float: left;
}

.slide-fade-enter-active, .slide-fade-reverse-enter-active {
  transition: all 1.8s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

.slide-fade-reverse-enter, .slide-fade-reverse-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

</style>>


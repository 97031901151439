<template>
  <v-app id="app">
    <v-app-bar app flat color="white" :absolute="$vuetify.breakpoint.mobile">
      <router-link to="/">
          <img src="@/assets/logo.png" alt="SARKO" style="cursor: pointer;" width="70">
        </router-link>
      <template v-if="!$vuetify.breakpoint.mobile" id="menu">
        <v-row 
          justify="space-around"
        >
          <v-col
            md="12"      
          > 
            <v-btn
              text
              color="grey lighten-1"
              class="float-right text-capitalize"
              to="/contact"
            >
              <span class="title-bar">Contato</span>
            </v-btn>

            <v-btn
              text
              color="grey lighten-1"
              class="float-right text-capitalize"
              to="/services"
            >
              <span class="title-bar">Serviços</span>
            </v-btn>

            <v-btn
              text
              color="grey lighten-1"
              to="/about"
              class="float-right text-capitalize"
            >
              <span class="title-bar">Sobre</span>
            </v-btn>
             <v-btn
                text
                color="grey lighten-1"
                to="/"
                class="float-right text-capitalize"
              >
              <span class="title-bar">Início</span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-app-bar>
      
    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Provides the application the proper gutter -->
        <!-- If using vue-router -->
        <router-view></router-view>
    </v-main>
    <v-bottom-navigation
      v-if="!hideMenu && $vuetify.breakpoint.mobile"
      fixed
      grow
      color="blue darken-2"
    >
      <v-btn
        v-for="(route, index) in bottomNavidationData"
        :key="index"
        :to="route.path"
        style="height: unset;"
      >
        <span v-text="route.title"/>
        <v-icon v-text="route.icon"/>
      </v-btn>
    </v-bottom-navigation>

      <Footer/>
      <!-- -->
  </v-app>
</template>
<script>
import Footer from './components/Footer'
export default {
  components: { Footer },
  data: () => ({
    fab: false,
    offset: -422,

    about: 'number',
    partners: 'value',

    value: 1500,
    number:300,

    bottomNavidationData: [
      {title: 'Casa', path: '/',  icon: 'mdi-home-variant'},
      {title: 'Contato', path: '/contact', icon: 'mdi-cellphone'},
      {title: 'Serviços', path: '/services', icon: 'mdi-shark-fin-outline'},
    ],

  }),
  computed: {
     hideMenu() {
      return this.$route.meta.hideMenu || false
    },

    targetAbout () {
      const value = this[this.about]
      if (!isNaN(value)) return Number(value)
      else return value
    },
  
    targetPartners () {
      const value = this[this.partners]
      if (!isNaN(value)) return Number(value)
      else return value
    },
    options () {
      return {
        offset: this.offset,
      }
    },

  }
}
</script>
<style lang="scss">


.title-bar {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 135%;

  color: #616161;
}


</style>

<template>
  <div class="app-color">
    <v-container>
      <br/>
      <h1 class="app-subtitle">Parceiros</h1>
      <v-row>
        <!-- Title Content -->
        <v-col
          cols="12"
          subtitle-1
          md="4"
          order="1"
          order-md="1"
        >
          <div class="container-text">
            <v-img src="../assets/dynatrace.png" width="120"></v-img>
            <h4>Application Performance Management</h4>
            <p>Solução de gerenciamento de desempenho para programas executados no local e na nuvem. Este software gerência a disponibilidade
              e o desempenho de aplicações de software e o impacto na experiência do usuário na forma de monitoramento profundo de transações,
              monitoramento sintético, monitoramento de usuários reais e monitoramento de rede.
            </p>
            <div class="line"/>
            <p style="color: #616161;">01</p>
          </div>
        </v-col>
        <v-col
          cols="12"
          subtitle-1
          md="4"
          order="2"
          order-md="1"
        >
          <div class="container-text">
            <v-img src="../assets/servicenow.png" width="120"></v-img>
            <h4>Application Performance Management</h4>
            <p>Solução de gerenciamento de desempenho para programas executados no local e na nuvem. Este software gerência a disponibilidade
              e o desempenho de aplicações de software e o impacto na experiência do usuário na forma de monitoramento profundo de transações,
              monitoramento sintético, monitoramento de usuários reais e monitoramento de rede.
            </p>
            <div class="line"/>
            <p style="color: #616161;">02</p>
          </div>
        </v-col>
        <v-col
          cols="12"
          subtitle-1
          md="4"
          order="2"
          order-md="1"
        >
          <div class="container-text">
            <v-img src="../assets/veritas-90x24.png" width="90" style="margin-bottom: 8px;"></v-img>
            <h4>Application Performance Management</h4>
            <p>Solução de gerenciamento de desempenho para programas executados no local e na nuvem. Este software gerência a disponibilidade
              e o desempenho de aplicações de software e o impacto na experiência do usuário na forma de monitoramento profundo de transações,
              monitoramento sintético, monitoramento de usuários reais e monitoramento de rede.
            </p>
            <div class="line"/>
            <p style="color: #616161;">03</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data: () => ({
    
  }),
}
</script>
<style lang="scss" scoped>

.app-color {
  background-color: #C4C4C4;
  width: 100vw;
}

.app-subtitle{
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 700 !important;
  line-height: 120% !important;

  color: #414148 !important;
}

h4 {
  font-family: 'Montserrat';
  font-size: 15px;
  line-height: 160%;

  color: #414148;
}

p {
  font-family: 'Montserrat';
  font-size: 15px;

  /* or 24px */

  text-align: justify;

  color: #212121;
}

.container-text {
  margin-top: 10%;
  margin-bottom: 12%;

}

.line {
  width: 370px;
  height: 1px;
  margin-bottom: 2%;
  background-color: white;
}

@media only screen and (max-device-width: 700px) {
  p {
    text-align: left !important;
    font-size: 20px !important;
  }
}
</style>


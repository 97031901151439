<template>
<div style="height: 100vh">
  <div class="app-color" >
    <v-container>
      <v-row style="margin-top: 5%">
        <!-- Image Content -->
        <v-col
          cols="12"
          subtitle-1
          md="6"
          order="2"
          order-md="1"
        >
          <v-img
            v-if="!$vuetify.breakpoint.mobile"
            transition="slide-y-transition"
            max-height="500"
            max-width="400"
            src="../assets/desktop.png"
            class="float-image-left"
          ></v-img>
        </v-col>
        <!-- Text Content  -->
        <v-col
          cols="12"
          subtitle-1
          md="6"
          order="2"
          order-md="1"
        >
          <transition
            appear
            name="slide-fade"
            transition="slide-x-transition"
          >
            <h1 class="app-subtitle">Sobre a Sarko</h1>
          </transition>
          <p class="span">A  SARKO  Digital  foi  criada  em  2016  com  a  missão  de  prover  
            integração  de  soluções  de Tecnologia da Informação de forma inteligente, com melhores 
            práticas de mercado, por um preço justo.
          </p><br/>
          <v-img
            src="../assets/peter.svg"
            class="float-image"
            max-width="550"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</div>
  
</template>
<script>
export default {
  data: () => ({
    
  }),
}
</script>
<style lang="scss" scoped>

.app-color {
  background-color: #424242;
  width: 100vw;
  height: 50vh;
}

.app-subtitle{
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 700 !important;
  line-height: 120% !important;

  color: white !important;
}

.span {
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 20px;
  text-align: justify;

  /* or 24px */
  color: #F6F6F6;;
}

.float-image {
  float: left;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));

  border: solid #777777;
}

.float-image-left {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  border: solid #777777;

}

.slide-fade-enter-active, .slide-fade-reverse-enter-active {
  transition: all 4.8s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

.slide-fade-reverse-enter, .slide-fade-reverse-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

@media only screen and (max-device-width: 700px) {
  .float-image {
    max-width: 320px !important;
  }
  .span {
    text-align: left !important;
  }
}

</style>



<template>
  <v-footer
    dark
    padless
  >
    <v-card
      flat
      tile
      class="grey darken-3 white--text text-center"
    >
      <v-row no-gutters style="width: 100vw;">
        <v-row>
          <v-col
            md="6"
          >
            <v-card-text>
              <div class="logo">
                <img src="@/assets/logo.png" alt="SARKO" style="cursor: pointer;" width="80">
              </div>
            </v-card-text>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            md="6"
          >
            <v-card-text id="footer-text">
              <p style="font-weight: 600;">Parceiros</p>
              <p>Spezi</p>
            </v-card-text>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            md="6"
          >
            <v-card-text id="footer-text">
              <p style="font-weight: 600;">Iniciativa</p>
              <p>Spezi</p>
            </v-card-text>
          </v-col>
        </v-row>
      </v-row>
      <v-divider></v-divider>

      <v-card-text>
        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4 white--text"
          icon
        >
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-text>
      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>SARKO</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
export default {
  data: () => ({
    icons: [
      'mdi-facebook',
      'mdi-twitter',
      'mdi-linkedin',
      'mdi-instagram',
    ],
  }),
}
</script>
<style lang="scss" scoped>

.app-color {
  background-color: #C4C4C4;
  width: 100vw;
}


p {
  font-family: 'Montserrat';
  font-size: 15px;
  line-height: 120%;
  justify-content: center;
  align-items: center;
  justify-items: center;
  text-align: center;

  /* or 24px */

  width: 300px;

  letter-spacing: -0.01em;

  color: white;
}

@media only screen and (max-device-width: 700px) {
  .logo {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  #footer-text {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}


</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Landpage from '../views/Landpage.vue'
import Specifications from '../views/Specifications'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LandPage',
    component: Landpage
  },
  {
    path: '/contact',
    name: 'Contato',
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  },
  {
    path: '/about',
    name: 'Sobre',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/services',
    name: 'Serviços',
    component: () => import(/* webpackChunkName: "about" */ '../views/Services.vue')
  },
  {
    path: '/specifications',
    name: 'Especificações',
    component: Specifications
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
